import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';

import { Posts as PostItems } from '../components/Posts';
import { Layout } from '../components/Layout';
import { Pagination } from '../components/Pagination';
import { PageContext } from '../models/page-context';

type Props = {
    pageContext: Required<PageContext>,
}

const Header = styled.h2`
    margin: 2rem auto;
    max-width: 900px;
    font-size: 1.4rem;
    text-align: center;
    color: var(--color-black);
    .small {
        font-weight: normal;
        font-size: 1rem;
        margin-left: 0.5rem;
        color: var(--color-gray);
    }
`;

const FilteredPosts: FunctionComponent<Props> = ({ pageContext }) => {
    const { first, last, currentPage, additionalContext } = pageContext;
    const { category, tag, url, siteUrl } = additionalContext;
    const pathPrefix = !!category ? `/categories/${category!.toLowerCase()}` : `/tags/${tag!.toLowerCase()}`;

    return (
        <Layout url={url} image={`${siteUrl}assets/icon.png`}>
            <Header>{category || tag || ''}<span className="small">{ !!category ? 'カテゴリの記事' : 'タグが付いた記事' }</span></Header>
            <PostItems posts={additionalContext!.posts} />
            <Pagination
                prev={ first ? null : currentPage === 2 ? pathPrefix : `${pathPrefix}/page/${currentPage - 1}`}
                next={ last ? null : `${pathPrefix}/page/${currentPage + 1}` }
            />
        </Layout>
    );
}

export default FilteredPosts;
